import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Provider as ReduxProvider } from 'react-redux';
import Helmet from 'react-helmet';

import { IAppProps } from './app.interface';
import { IUser } from '../components/user/user.interface';
import Routing from '../config/route';
import session from '../model/session';
import { clientURI } from '../api/clientURI';
import { userAUTH } from '../config/authetication';
import { store } from '../store/store';

import './app.css';

export class App extends React.Component<IAppProps> {
    constructor(props: IAppProps) {
        super(props);
        this.state = {
            user: null,
            userCheck: false,
        };

        this.getSession();
    }

    componentWillUnmount() {
        session.removeTopic();
    }

    async getSession() {
        await session.fetchLocal();
        const user: IUser = session.getUser();
        userAUTH(user, this.props.history);
        if (user._id) {
            this.setState({
                user: user,
            }, () => this.checkRoute());
        }

        this.setState({
            userCheck: true
        })
    }

    handleLogin = (user: IUser) => {
        console.log('Handle login', user);

        session.setUser(user);
        this.setState({ user }, () => this.checkRoute());
    }

    checkRoute() {
        if (!session.getUser()._id) {
            if (!/login/.test(window.location.pathname)) {
                window.location.replace('/login?redirect=' + encodeURIComponent(window.location.pathname));
                console.log(window.location.search)
            }
        } else {
            if (/redirect/.test(window.location.search)) {
                window.location.replace(decodeURIComponent(window.location.search.replace('?redirect=', '')));
            } else if (/login/.test(window.location.pathname)) {
                window.location.replace('/login?redirect=/');
            }
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Jamit!</title>
                    <meta name="Jamit!" content="Jam and collaborates to have fun!" />
                    <link
                        rel="stylesheet"
                        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                        integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                        crossOrigin="anonymous"
                    />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap"
                        rel="stylesheet"
                    />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap"
                        rel="stylesheet"
                    />
                    <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                </Helmet>
                <ApolloProvider client={clientURI}>
                    <ReduxProvider store={store}>
                        <Routing />
                    </ReduxProvider>
                </ApolloProvider>
            </>
        )
    }
}
