 export const styles = {
    card:{
        background:"transparent",
        fontFamily:"Montserrat, sans-serif",
        boxShadow:"none",
        position:"absolute",
        overflow:"hidden",
        width:"230px",
        height:"350px",

        border: 'none',
        borderColor: 'transparent',
        borderStyle: 'solid !important',
        borderWidth: 'thick !important',
        borderRadius: 'var(--card-border-radius) !important',

        '&[data-card-selected="true"]': {
            borderColor: 'var(--card-border-colour) !important',
        },
        '& *': {
            boxSizing: 'border-box',
        },
    },

    n_card:{
        background:"transparent",
        fontFamily:"Montserrat, sans-serif",
        boxShadow:"none",
        position:"relative",
        overflow:"hidden",
        marginBottom:"-280px",
        width:"230px",
        height:"350px",
        borderRadius:"10px",
    },

    deck_card: {
        background:"transparent",
        fontFamily:"Montserrat, sans-serif",
        boxShadow:"none",
        position:"relative",
        overflow:"hidden",
        marginBottom:"-355px",
        width:"230px",
        height:"350px",
        borderRadius:"10px",
    },


    /* Front Card Cover */
    cardContent:{
        position:"absolute",
        height:"calc(100%)",
        padding:"20px",
        top:"0",
        left:"0",
        background:"#FFF",
        transition:"0.5s",
        transformStyle:"preserve-3d",
        transform:"rotateY(0deg)",
        border:"0.5px solid #c4c4c4",
        borderRadius:"10px",
        opacity:1,
        zIndex:1
    },

    cardContentFlip:{
        position:"absolute",
        height:"calc(100%)",
        padding:"20px",
        background:"#FFF",
        top:"0",
        left:"0",
        transition:"0.5s",
        transformStyle:"preserve-3d",
        transform:"rotateY(180deg)",
        border:"0.5px solid #c4c4c4",
        borderRadius:"10px",
        opacity:0,
        zIndex:0
    },

    iconRight:{
        float:"right",
        '&:focus':{
            outline:"none"
        }
    },

    sendRight:{
        float:"right",
        color:"#4173D6",
        opacity:1,
        '&:focus':{
            outline:"none"
        }
    },

    sendRightHide:{
        float:"right",
        color:"#4173D6",
        opacity:0
    },

    contentWrapper:{
        position:"relative",
        margin:"10px 0 10px 0",
        minWidth:"188px",
        height:"calc(100% - 140px)"
    },

    content:{
        width:"100%",
        fontFamily:"Inter",
        fontWeight:"500",
        '&:focus':{
            outline: "0px solid transparent",
        },
        overflowWrap: "break-word",
        wordWrap: "break-word",
    },

    counterShow:{
        display:"inline-block",
        fontSize:"14px",
        margin:"0",
        marginTop:"0px",
        opacity:1,
        color:"#C4C4C4"
    },

    counterHide:{

        opacity:0
    },

    username:{
        fontSize:"14px",
        margin:"0",
        fontWeight:"bold"
    },

    pin:{
        fontSize:"12px",
        marginLeft:"2px",
        marginTop:"2px"
    },
    /* Back Card Cover */
    cardCover:{
        position:"absolute",
        height:"calc(100%)",
        width:"100%",
        padding:"20px",
        transition:"0.5s",
        top:"0",
        left:"0",
        background:"#FFF",
        opacity:1,
        transformStyle:"preserve-3d",
        transform:"rotateY(180deg)",
        border:"0.5px solid #c4c4c4",
        borderRadius:"10px",
        zIndex:0
    },

    cardCoverFlip:{
        position:"absolute",
        height:"calc(100%)",
        width:"100%",
        padding:"20px",
        top:"0",
        left:"0",
        transition:"0.5s",
        background:"#FFF",
        opacity: 1,
        transformStyle:"preserve-3d",
        transform:"rotateY(0deg)",
        border:"0.5px solid #c4c4c4",
        borderRadius:"10px",
        zIndex:1
    },

    cardCoverIconWrapper:{
        margin:"40px 0",
    },

    cardCoverCategory:{
        fontSize:"18px",
        textAlign:"center",
        fontWeight:"bold"
    },

    cardCoverInfo:{
        fontSize:"14px",
        textAlign:"center"
    },

    /* Menu */
    menuIcon:{
        marginRight:"5px"
    },

    imgHolder:{
        position:"relative",
        width:"100%",
        height:"170px",
        overflow:"hidden",
        "&:hover $imgDelete":{
            background:"rgba(0,0,0, 0.7)",
            color:"white"
        }
    },

    imgDelete:{
        position:"absolute",
        right:"5px",
        top:"5px",
        width:"20px",
        outline:"none",
        height:"20px",
        background:"transparent",
        color:"transparent",
        zIndex:"2",
        transition:"0.5s",
    },

    dropZone:{
        width:"100%",
        height:"170px",
        border:"1px dashed #C4C4C4",
        background:"#F2F2F2",
        color:"#C4C4C4",
        lineHeight: "160px",
        fontSize:"12px",
        fontFamily:"Montserrat",
        textAlign:"center"
    },
    draggableStackContainer: {
        border: 'none',
        borderColor: 'transparent',
        borderStyle: 'solid !important',
        borderWidth: 'thick !important',
        borderRadius: 'var(--card-border-radius) !important',
        overflow: 'hidden',
    },
    draggableStackContainerSelected: {
        borderColor: 'var(--card-border-colour) !important',
    },
    arrowLeftPairOverlay:{
        backgroundColor: 'grey',
        borderRadius: '15px',
        position: 'absolute',
        top: '160px',
        left: '-5px',
        zIndex: '1000',
        color: 'white'
    },
    arrowRightPairOverlay:{
        backgroundColor: 'grey',
        borderRadius: '15px',
        position: 'absolute',
        top: '160px',
        left: '215px',
        zIndex: '1000',
        color: 'white'
    },
    arrowUpPairOverlay:{
        backgroundColor: 'grey',
        borderRadius: '15px',
        position: 'absolute',
        top: '-3px',
        left: '105px',
        zIndex: '1000',
        color: 'white'
    },
    arrowDownPairOverlay:{
        backgroundColor: 'grey',
        borderRadius: '15px',
        position: 'absolute',
        top: '330px',
        left: '105px',
        zIndex: '1000',
        color: 'white'
    },

}

export default styles;
