// Draw functions
// Main draw process 
// Use current deck infos & drawConfig from YAML
// Example drawConfig = [Story%label%label(5),Ending(2)]
import socket from '../api/socket'

// const roleChecker = (drawRole,playerRole) => {
//     if(drawRole === playerRole){
//         return true
//     }else{
//         return false
//     }
// }

export const drawToMany = (participants,deck, drawConfig) => {
    let dom = []
    for(let i = 0; i<participants.length; i++){
        var hand = drawLoop(deck,drawConfig)

        let player = {
          uid: participants[i].uid,
          objects: hand,
        }

        dom.push(player);
    }

    // console.log(dom);
    // console.log(deck);

    socket.emit("Game Draw To Many",{dom,deck})
}
  
export const drawLoop = (deck,drawConfig) => {
    var regExp = /\(([^)]+)\)/;
    let result = []
    
    for(let i = 0; i<drawConfig.length; i++){
        let temp = drawConfig[i]
        if(temp.indexOf("%") !== -1){
            let cat = temp.substring(0,temp.indexOf("%"))
            let labels = temp.substring(temp.indexOf("%")+1, temp.indexOf("(")).split("%")

            var matches = regExp.exec(temp)
            let amount = parseInt(matches[1])

            var draw = drawCard(deck,amount,cat,labels)
            result.push(draw)
        }else{
            let cat = temp.substring(0,temp.indexOf("("))
            let labels = null

            var matches = regExp.exec(temp)
            let amount = parseInt(matches[1])

            var draw = drawCard(deck,amount,cat,labels)
            result.push(draw)
        }
    }

    let hand = []
    for(let i = 0; i<result.length; i++){
        hand.push(...result[i])
    }

    return hand;
}
  
const drawCard = (arr,n,cat,labels) => {
    var categoryToDraw = [];
    var result = [];

    categoryToDraw = arr[cat]

    for(var i = 0; i<n; i++){
        result.push(drawOneCard(categoryToDraw,labels))
    }

    // After draw
    arr[cat] = categoryToDraw;

    return result
}

const drawOneCard = (cards,labels) => {
    var result;

    if(labels){
        // draw specific label from category

        let cardWithSpecificLabel = cards.filter((n)=>{
            return labels.some((et)=> n.label.includes(et) )
        })

        let r = Math.floor(Math.random() * cardWithSpecificLabel.length) + 1;
        result = cardWithSpecificLabel[r]
        
        // splice process
        // **compare content only - becareful same content
        let index = cards.findIndex((n)=>{return n.content === result})
        cards.splice(index,1)
    }else{
        // draw cards from category
        let r = Math.floor(Math.random() * cards.length);

        result = cards[r]

        // splice process
        cards.splice(r,1)
    }

    return result
}

