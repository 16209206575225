import React from 'react'
import {withStyles, Grid, IconButton, Tooltip, SvgIcon} from '@material-ui/core'
import {LibraryBooks, CloseRounded, PanToolOutlined} from '@material-ui/icons';
import GameLibrary from './gameLibrary/gameLibrary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMousePointer } from '@fortawesome/free-solid-svg-icons'
import EE from '../../../../api/eventemitter'
const styles = {
    wrapper:{
        position:"absolute",
        top:"3rem",
        height: "calc(100% - 3rem)",
        zIndex:"2",
        pointerEvents: "none",
    },

    menu:{
        position:"absolute",
        left:"250px",
        top:"0px",
        height:"100%",
        padding:"0 10px",
        transition:"0.5s",
        pointerEvents: "auto"
    },

    menuClose:{
        position:"absolute",
        left:"0",
        top:"0px",
        height:"100%",
        padding:"0 10px",
        transition:"0.5s",
        pointerEvents: "auto"
    },

    menuBtn:{
        marginTop:"10px",
        background:"#FFF",
        boxShadow:"0px 5px 5px #C4C4C4",
        '&:focus':{
            outline:"none",
        }
    },

    menuIcon:{
        color:"#4F4F4F",
        transition:"0.5s",
        borderRadius:"0px",
        '&:focus':{
            outline:"none",
        },
        "&:hover":{
            background:"rgba(0,0,0,0.1)"
        },
    },

    selected:{
        color:"#4173D6",
        borderRadius:"0px",
        transition:"0.5s",
        '&:focus':{
            outline:"none",
        },
        "&:hover":{
            background:"rgba(0,0,0,0.1)"
        },
    },

    content:{
        top:"0",
        overflowY:"auto",
        overflowX:"hidden",
        height:"100%",
        background: "#E1E1E1",
        width: "250px",
        position: "absolute",
        zIndex:"2",
        transition:"0.5s",
        pointerEvents: "auto"
    },

    contentClose:{
        top:"0",
        overflowY:"auto",
        marginLeft:"-250px",
        height:"100%",
        background: "#E1E1E1",
        width: "250px",
        position: "absolute",
        zIndex:"2",
        transition:"0.5s",
        pointerEvents: "auto"
    },

    close:{
        color:"#4F4F4F",
        '&:focus':{
            outline:"none",
        }
    },

    panelHeader:{
        margin:"0",
        padding:"0 1rem",
        fontWeight:"bold",
        color:"#4F4F4F",
        fontSize:"18px",
    },

    panelSubheader:{
        margin:"0",
        padding:"0 1rem",
        color:"#4F4F4F",
        fontSize:"12px",
    }
}
class GameLeftPanel extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            page: null,
            open: false,
            mode: "Cursor",
            canvasFocus: true,
        }
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.navigationModeShortcut.bind(this));
    }

    componentDidMount(){
        document.addEventListener("keydown", this.navigationModeShortcut.bind(this) );

        EE.on("Canvas Defocus",()=>{
            if(this.state.canvasFocus == true){
                this.setState({
                    canvasFocus: false,
                })
                this.props.clearMultiSelect();
            }
            // console.log("Canvas Defocus");
        })

        EE.on("Canvas Focus",()=>{
            if(this.state.canvasFocus == false){
                this.setState({
                    canvasFocus: true,
                })
            }
            // console.log("Canvas Focus");
        })

        EE.on("Spectator Popup",()=>{
            this.setState({
                canvasFocus: false,
            })
        })
    }

    navigationModeShortcut = (e) => {
        if(e.key === "v" && this.state.canvasFocus){
            this.props.updMode("Cursor")
        }

        if(e.key === "h" && this.state.canvasFocus){
            this.props.updMode("Hand")
        }

        if(e.key === '+' && this.state.canvasFocus){
            this.props.handleZoomIn();
        }

        if(e.key === '=' && this.state.canvasFocus){
            this.props.handleZoomIn();
        }

        if(e.key === '-' && this.state.canvasFocus){
            this.props.handleZoomOut();
        }

        if(e.key === '_' && this.state.canvasFocus){
            this.props.handleZoomOut();
        }

    }

    render(){
        const {page} = this.state
        const {objects, classes, updMode, cursorMode, isLeader} = this.props

        return(
            <div onClick={()=>{EE.emit("Canvas Defocus")}} className={classes.wrapper}>
                <div className={this.state.open ? classes.menu : classes.menuClose}>
                    <div className={classes.menuBtn}>
                        {isLeader &&
                            <Tooltip title={"Library"} placement="right">
                                <IconButton onClick={()=>this.openPanel("Library")} className={this.state.page === "Library" ? classes.selected : classes.menuIcon}>
                                    <LibraryBooks/>
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip placement="right" title={<p style={{margin:"0"}}>Move <span style={{color:"#D1D1D1", marginLeft:"20px"}}>V</span></p>}>
                            <IconButton  onClick={()=>updMode("Cursor")} className={cursorMode === "Cursor" ? classes.selected : classes.menuIcon} style={{padding:"0.75rem 0.5rem 0.75rem 1rem", width:"100%"}}>
                                {/* <FontAwesomeIcon icon={faMousePointer} style={{fontSize:"20px"}}/> */}
                                <SvgIcon>
                                    <svg width="40px" height="40px" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        {/* <path d="M0 16.015V0L11.591 11.619H4.55L4.399 11.743L0 16.015Z" fill="white" /> */}
                                        <path d="M1 2.40698V13.595L3.969 10.729L4.129 10.59H9.165L1 2.40698Z" fill={cursorMode === "Cursor" ? "#4173D6" : "#4f4f4f"}/>
                                    </svg>
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>

                        <Tooltip placement="right" title={<p style={{margin:"0"}}>Hand tool <span style={{color:"#D1D1D1", marginLeft:"20px"}}>H</span></p>}>
                            <IconButton  onClick={()=>updMode("Hand")} className={cursorMode === "Hand" ? classes.selected : classes.menuIcon}>
                                <PanToolOutlined/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <div className={this.state.open ? classes.content : classes.contentClose}  id="left-panel">
                    <div style={{margin:"10px"}}>
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={9}>
                                <p className={classes.panelHeader}>
                                    {this.state.page}
                                </p>
                            </Grid>
                            <Grid item xs={3}>
                                <IconButton className={classes.close} onClick={()=>this.closePanel()}>
                                    <CloseRounded />
                                </IconButton>
                            </Grid>
                            <Grid item xs={12}>
                                {this.state.page==="Library" &&
                                    <p className={classes.panelSubheader}>
                                        List of all the cards in the jam
                                    </p>
                                }
                            </Grid>
                        </Grid>

                        {this.pageSelection(page, objects)}
                    </div>
                </div>
            </div>
        )
    }

    openPanel = (select) => {
        this.setState({
            open: true,
            page: select
        })
    }

    closePanel = () =>{
        this.setState({
            open: false,
            page: null
        })
    }



    handleCategoryClick = (category) =>{
        this.setState({
            [category]: !this.state[category]
        })
    }

    handleLabelClick = (label) =>{
        this.setState({
            [label]: !this.state[label]
        })
    }

    pageSelection = (page, objects) =>{
        switch(page){
            case "Library":
                const {
                    addNewLibraryCard,
                    moveViewToCard,
                    categoryColorMap,
                    deleteLibrary,
                    selectCardsInLibrary,
                    selectedIDs,
                    canDragLibCard,
                    isLeader,
                    startSelectCardsLibrary,
                    clearLibrarySelection,
                    duplicateSingleLibraryCard,
                } = this.props;

                return(
                    isLeader &&
                    <GameLibrary
                        objects={objects}
                        addNewLibraryCard={addNewLibraryCard}
                        moveViewToCard={moveViewToCard}
                        deleteLibrary = {deleteLibrary}
                        duplicateSingleLibraryCard={duplicateSingleLibraryCard}
                        categoryColorMap={categoryColorMap}
                        leftPanelState = {this.state}
                        handleCategoryClick = {this.handleCategoryClick}
                        handleLabelClick = {this.handleLabelClick}
                        selectCardsInLibrary = {selectCardsInLibrary}
                        selectedIDs = {selectedIDs}
                        canDragLibCard = {canDragLibCard}
                        startSelectCardsLibrary = {startSelectCardsLibrary}
                        clearLibrarySelection = {clearLibrarySelection}
                        imgData = {this.props.imgData}
                        canvasFocus = {this.state.canvasFocus}
                    />
                )
            default:
                break
        }
    }
}

export default withStyles(styles)(GameLeftPanel)
